import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGVgw1n1dJEN1WIKifF9E3fGZX6mP3w-g",
  authDomain: "site-web-markus.firebaseapp.com",
  projectId: "site-web-markus",
  storageBucket: "site-web-markus.appspot.com",
  messagingSenderId: "862307572843",
  appId: "1:862307572843:web:526cbef293d697ad5de52e",
  measurementId: "G-BGPX6RVKMQ",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { db, auth, timestamp };
